import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'shared/hooks/use-router';
import PropTypes from 'prop-types';
import { useAuth } from 'shared/hooks/use-auth';
import { getReturnUrl } from 'shared/utils/routing';
import { useIsSmallScreen } from 'shared/hooks/use-is-small-screen';

type GuestGuardProps = {
  children: ReactNode;
};

export const XGuestGuard: FC<GuestGuardProps> = (props) => {
  const { children } = props;
  const { isAuthenticated, user } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);
  const isSmallScreen = useIsSmallScreen();

  useEffect(
    () => {
      if (!router.isReady) {
        return;
      }

      if (isAuthenticated) {
        router.replace(getReturnUrl(router, user, isSmallScreen));
      } else {
        setChecked(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady, isAuthenticated, user, isSmallScreen]
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // Not authenticated / authorized.

  return <>{children}</>;
};

XGuestGuard.propTypes = {
  children: PropTypes.node,
};
