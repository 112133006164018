import React, { Children } from 'react';
import { View } from 'react-native';
import { XStyle } from 'theme/types';
import { AlignItems, FlexDirection, JustifyContent } from 'types/css';

type Props = {
  alignItems?: AlignItems;
  children?: React.ReactNode;
  direction?: FlexDirection;
  display?: 'flex' | 'none';
  flexGrow?: number;
  flexShrink?: number;
  justifyContent?: JustifyContent;
  marginLeft?: number | 'auto';
  marginRight?: number | 'auto';
  spacing?: number;
  style?: XStyle;
  maxWidth?: number;
};

export function XStack({
  alignItems,
  children,
  direction = 'column',
  display = 'flex',
  flexGrow = 1,
  flexShrink,
  justifyContent,
  marginLeft,
  marginRight,
  spacing = 0,
  style,
  maxWidth,
}: Props) {
  const filteredChildren = Children.map(children, (child) => child)?.filter(
    // This is a hack to remove null children, which can cause issues
    (child) => child?.toString() !== undefined
  );

  return (
    <View
      style={{
        alignItems,
        display,
        flexDirection: direction,
        flexGrow,
        flexShrink,
        justifyContent,
        marginLeft,
        marginRight,
        maxWidth,
        gap: spacing,
        ...style,
      }}
    >
      {filteredChildren != null && filteredChildren.length > 0
        ? filteredChildren.map((child, i) => <React.Fragment key={i}>{child}</React.Fragment>)
        : null}
    </View>
  );
}
