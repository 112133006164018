import { TextField } from '@mui/material';
import { KeyValue, XTextFieldProps } from './types';
import { mapKeyboardTypeToInputMode } from './utils';
import { mapIconNameToIconComponent } from 'shared/x-icons/utils';
import { useModifiedStyleForWeb } from 'shared/theme';

export function XTextField(props: XTextFieldProps) {
  const style = useModifiedStyleForWeb({
    backgroundColor: 'none',
    ...props.style,
  });
  const inputStyle = useModifiedStyleForWeb(props.inputStyle);
  const inputMode = props.keyboardType != null ? mapKeyboardTypeToInputMode[props.keyboardType] : undefined;
  const RightIcon = props.rightIcon ? mapIconNameToIconComponent[props.rightIcon] : undefined;
  return (
    <TextField
      {...props}
      onChange={(e) => props.onChangeText?.(e.target.value)}
      inputProps={{ inputMode, maxLength: props.maxLength }}
      InputProps={{
        endAdornment: RightIcon ? <RightIcon {...props.rightIconProps} /> : undefined,
        style: inputStyle,
      }}
      onKeyPress={(e) =>
        props.onKeyPress?.({
          nativeEvent: {
            key: e.key as KeyValue,
          },
        })
      }
      style={style}
    />
  );
}
