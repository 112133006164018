import { Box, CircularProgress } from '@mui/material';
import { XCircularLoadingIndicatorProps } from './types';

export function XCircularLoadingIndicator({
  size: _, // TODO: Implement size prop
}: XCircularLoadingIndicatorProps) {
  return (
    <Box display="flex" flexGrow={1} justifyContent="center" my={4}>
      <CircularProgress />
    </Box>
  );
}
