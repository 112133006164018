import { KeyboardEvent, RefObject, useEffect, useRef, useState } from 'react';
import { XTextField } from 'shared/x-components/x-text-field';
import { XStack } from 'shared/x-components/x-stack';
import { toast } from 'shared/x-components/x-toast';
import { graphql } from 'shared/gql';
import { XTypography } from 'shared/x-components/x-typography';
import { useMutationWithErrorLogging } from 'shared/hooks/useMutationWithErrorLogging';
import { Platform, View } from 'react-native';
import { XLink } from 'shared/x-components/x-link';

const otpFormMutation = graphql(/* GraphQL */ `
  mutation OtpForm($input: ResendOtpInput!) {
    resendOtp(input: $input) {
      error
    }
  }
`);

type Props = {
  userEmail?: string;
  userInputPassword: string;
  onSendEmail?: () => Promise<string | null | undefined>;
  onSubmit: (otp: string) => Promise<boolean>;
};

export function XOtpForm({ userEmail, userInputPassword, onSendEmail, onSubmit }: Props) {
  const [otpArray, setOtpArray] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [resendOtp] = useMutationWithErrorLogging(otpFormMutation, {
    variables: {
      input: {
        channel: 'sms',
        userEmail: userEmail ?? '',
        currentPassword: userInputPassword,
      },
    },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRefs.current[0]?.focus();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onPaste = (e: React.ClipboardEvent, index: number) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text').split('');
    if (paste.every((item) => !isNaN(Number(item)))) {
      const newInputValue = [...otpArray];
      for (let i = 0; i < paste.length; i++) {
        if (index + i < otpArray.length) {
          newInputValue[index + i] = paste[i];
        }
      }
      setOtpArray(newInputValue);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>, index: number) => {
    const keyCode = parseInt(e.key);
    if ((e.metaKey && e.key === 'v') || (keyCode >= 0 && keyCode <= 9)) {
      return;
    } else if (e.key === 'ArrowRight') {
      inputRefs.current[index + 1]?.focus();
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      inputRefs.current[index - 1]?.focus();
    }
  };

  const onChangeText = (input: string, index: number) => {
    if (input.length === 6 && !isNaN(Number.parseInt(input))) {
      setOtpArray(input.split(''));
      return;
    } else if (input.length > 1) {
      return;
    }
    if (input === '' || !isNaN(Number.parseInt(input))) {
      setOtpArray((preValue) => {
        const newArray = [...preValue];
        newArray[index] = input;
        return newArray;
      });

      if (input !== '' && index < otpArray.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLDivElement>, index: number) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setOtpArray((prevValue) => {
        const newArray = [...prevValue];
        return newArray;
      });

      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };
  const clearInputs = () => {
    setOtpArray(['', '', '', '', '', '']);
    inputRefs.current[0]?.focus();
  };
  const handleResendOtp = async () => {
    setIsSubmitting(true);
    const { data } = await resendOtp();
    if (data?.resendOtp?.error) {
      setErrMsg(data.resendOtp.error);
    } else {
      setErrMsg(null);
      toast.success('New code sent!');
    }
    setIsSubmitting(false);
    clearInputs();
    setTimeout(() => {
      inputRefs.current[0]?.focus();
    }, 1);
  };

  useEffect(() => {
    if (otpArray.every((value) => value !== '')) {
      setIsSubmitting(true);
      void onSubmit(otpArray.join('')).then((success) => {
        if (!success) {
          setErrMsg('Invalid or expired code');
          clearInputs();
          setTimeout(() => {
            inputRefs.current[0]?.focus();
          }, 1);
          setIsSubmitting(false);
        } else {
          setErrMsg(null);
        }
      });
    }
  }, [otpArray]);

  const handleSendEmail = async () => {
    setIsSubmitting(true);
    const emailErr = await onSendEmail?.();
    if (emailErr) {
      setErrMsg(emailErr);
    } else {
      setErrMsg(null);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <XStack direction="column" maxWidth={300} marginLeft="auto" marginRight="auto" spacing={8}>
        <XStack direction="row" spacing={8}>
          {otpArray.map((value: string | number, i: number) => (
            <XTextField
              // eslint-disable-next-line react/no-array-index-key -- This is a static array
              key={i}
              disabled={isSubmitting}
              inputRef={((el?: HTMLInputElement) => el && (inputRefs.current[i] = el)) as unknown as RefObject<any>}
              inputMode="numeric"
              maxLength={6}
              inputStyle={{
                paddingStart: 4,
                paddingBottom: 16,
              }}
              style={{
                display: 'flex',
                flexShrink: 1,
                height: Platform.OS === 'web' ? 60 : 48,
                maxWidth: 50,
              }}
              type="text"
              value={String(value)}
              onChangeText={(text) => {
                onChangeText(text, i);
              }}
              onKeyUp={(e) => {
                onKeyUp(e, i);
              }}
              onKeyDown={(e) => {
                onKeyDown(e, i);
              }}
              onPaste={(e) => {
                onPaste(e, i);
              }}
              autoComplete="one-time-code"
              accessKey={String(i)}
            />
          ))}
        </XStack>
        {errMsg && !isSubmitting && (
          <XTypography color="red" variant={Platform.OS === 'web' ? 'body2' : 'caption'}>
            {errMsg}
          </XTypography>
        )}
      </XStack>
      <View>
        <XTypography variant={Platform.OS === 'web' ? 'body1' : 'body2'} style={{ cursor: 'pointer', marginTop: 32 }}>
          Didn&apos;t receive your code?{' '}
          <XLink
            style={{ fontWeight: '600' }}
            onClick={() => {
              void handleResendOtp();
            }}
            variant={Platform.OS === 'web' ? 'body1' : 'body2'}
          >
            Get a new one
          </XLink>{' '}
          {onSendEmail ? (
            <>
              <XTypography
                variant="body1"
                style={{
                  cursor: 'pointer',
                }}
              >
                or request an{' '}
              </XTypography>
              <XLink
                style={{ fontWeight: '600' }}
                onClick={() => {
                  void handleSendEmail();
                }}
              >
                email
              </XLink>
            </>
          ) : null}
        </XTypography>
      </View>
    </>
  );
}
