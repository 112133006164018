import { XLock } from 'shared/x-icons';
import { XLink } from 'shared/x-components/x-link';
import { useState } from 'react';
import { useIsSmallScreen } from 'shared/hooks/use-is-small-screen';
import { LoginFormik } from 'shared/x-pages/savvy-auth/x-login-types';
import { FragmentOf, graphql, unmaskFragment } from 'shared/gql';
import { XTextField } from 'shared/x-components/x-text-field';
import { XStack } from 'shared/x-components/x-stack';
import { XTypography } from 'shared/x-components/x-typography';
import { View } from 'react-native';
import { XLoadingButton } from 'shared/x-components/x-loading-button';
import { XForm } from 'shared/x-components/x-form';
import { useRouter } from 'shared/hooks/use-router';
import { toTitleCase } from 'shared/utils/formatting';

export const XLoginFormFragment = graphql(/* GraphQL */ `
  fragment LoginForm on Client {
    user {
      email
    }
  }
`);

type Props = {
  clientFrag?: FragmentOf<typeof XLoginFormFragment>;
  formik: LoginFormik;
};
export function XLoginForm({ clientFrag, formik }: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const isSmallScreen = useIsSmallScreen();
  const client = unmaskFragment(XLoginFormFragment, clientFrag);
  const router = useRouter();

  return (
    <XForm noValidate onSubmit={formik.handleSubmit}>
      <XStack>
        <XTextField
          autoComplete="email"
          inputMode="email"
          autoFocus={!client?.user.email && !isSmallScreen}
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ' '}
          label="Email Address"
          keyboardType="email-address"
          onBlur={() => formik.setFieldTouched('email', true)}
          onChangeText={(newValue) => {
            formik.setFieldValue('email', newValue);
            formik.setFieldTouched('email', true, false);
          }}
          textContentType="emailAddress"
          type="email"
          value={formik.values.email}
        />
        <XTextField
          autoComplete="current-password"
          error={Boolean(formik.touched.password && (formik.errors.password || formik.errors.submit))}
          fullWidth
          autoFocus={!!client?.user.email && !isSmallScreen}
          helperText={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : formik.touched.password && formik.errors.submit
              ? toTitleCase(formik.errors.submit)
              : ' '
          }
          label="Password"
          onBlur={() => formik.setFieldTouched('password', true)}
          onChangeText={(newValue) => {
            formik.setFieldValue('password', newValue);
            formik.setFieldTouched('password', true, false);
          }}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          rightIcon={showPassword ? 'eye-off' : 'eye'}
          rightIconProps={{
            color: 'action',
            cursor: 'pointer',
            onClick: () => setShowPassword(!showPassword),
          }}
          style={{ paddingBottom: 24 }}
        />
      </XStack>
      <XStack
        direction={isSmallScreen ? 'column-reverse' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        spacing={16}
      >
        <View
          style={{
            display: 'flex',
            flexGrow: isSmallScreen ? 1 : 0,
          }}
        >
          <XLink color="textSecondary" onClick={() => router.push('/reset-password')} variant="body2">
            Forgot your password?
          </XLink>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: isSmallScreen ? 1 : 0,
            width: isSmallScreen ? '100%' : undefined,
          }}
        >
          <XLoadingButton
            disabled={formik.isSubmitting || formik.errors.email != null || formik.errors.password != null}
            fullWidth={isSmallScreen}
            loading={formik.isSubmitting}
            size="large"
            type="submit"
            onClick={() => {
              if (!formik.errors.email && !formik.errors.password) {
                formik.handleSubmit();
              }
            }}
            variant="contained"
          >
            {!isSmallScreen && <XLock color="#FFFFFF" />}
            <XTypography
              fontWeight="600"
              style={{
                color: 'white',
                marginStart: 12,
              }}
            >
              Login
            </XTypography>
          </XLoadingButton>
        </View>
      </XStack>
    </XForm>
  );
}
