import { ReactNode } from 'react';
import { Platform, View, useWindowDimensions } from 'react-native';
import { XSavvyLogoHeader, LOGO_HEIGHT } from 'shared/x-components/x-savvy-logo-header';
import { XSavvyFooter } from 'shared/x-components/x-savvy-footer';
import { useSafeAreaInsets } from 'shared/hooks/use-safe-area-insets';

const LOGIN_CONTAINER_MAX_WIDTH = 512;
const MARGIN_VERT = 32;

export function XLoginLayout({
  maxWidth = LOGIN_CONTAINER_MAX_WIDTH,
  children,
}: {
  maxWidth?: number;
  children: ReactNode;
}) {
  const { top: topInset, bottom: bottomInset } = useSafeAreaInsets();
  const { height: totalScreenHeight, width: totalScreenWidth } = useWindowDimensions();

  return (
    <View
      style={{
        height: totalScreenHeight - topInset - bottomInset,
      }}
    >
      <View style={{ height: LOGO_HEIGHT, marginVertical: MARGIN_VERT }}>
        <XSavvyLogoHeader />
      </View>
      <View
        style={{
          alignItems: Platform.OS === 'web' ? 'center' : undefined,
          justifyContent: 'center',
          // 85% of the screen, but we must factor in the heights of the logo, all margins, and the unusable top inset (iPhone notch, etc.)
          minHeight: 0.85 * totalScreenHeight - LOGO_HEIGHT - 3 * MARGIN_VERT - topInset,
        }}
      >
        <View style={{ maxWidth, width: totalScreenWidth - 48 }}>{children}</View>
      </View>

      <View
        style={{
          // Thanks to the math above, we're guaranteed to have 15% of the screen height here
          minHeight: 0.15 * totalScreenHeight,
        }}
      />
      <View
        style={{
          position: 'relative',
          bottom: MARGIN_VERT,
        }}
      >
        <XSavvyFooter />
      </View>
    </View>
  );
}
