import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'shared/hooks/use-router';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from 'shared/hooks/use-auth';
import { XHead } from 'shared/x-components/x-head';
import { withGuestGuard } from 'shared/hocs/with-guest-guard';
import type { LoginFormik } from 'shared/x-pages/savvy-auth/x-login-types';
import { apiHost } from 'shared/api/api-host';
import { toast } from 'shared/x-components/x-toast';
import { useTheme } from 'shared/hooks/use-theme';
import { getReturnUrl } from 'shared/utils/routing';
import { XLoginLayout } from 'shared/x-pages/savvy-auth/x-login-layout';
import { XCircularLoadingIndicator } from 'shared/x-components/x-circular-loading-indicator';
import { OtpChannel, errMsgToOtpChannel, getAuthHeader, getLoginContext } from 'shared/utils/savvy-auth';
import { useLazyQueryWithErrorLogging } from 'shared/hooks/useLazyQueryWithErrorLogging';
import { useIsSmallScreen } from 'shared/hooks/use-is-small-screen';
import { AuthFactor, LoginData } from 'shared/utils/savvy-auth';
import { XLoginForm, XLoginFormFragment } from 'shared/x-pages/savvy-auth/x-login-form';
import { XOtpLoginForm } from 'shared/x-pages/savvy-auth/x-otp-login-form';
import { LoginReturn } from 'shared/contexts/jwt-context';
import { ResultOf, graphql } from 'shared/gql';
import { XLink } from 'shared/x-components/x-link';
import { XTypography } from 'shared/x-components/x-typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Platform, View } from 'react-native';
import { Variant } from '@mui/material/styles/createTypography';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer';

const MAX_STRING_LENGTH = 255;

export const XLoginQuery = graphql(
  /* GraphQL */ `
    query XLogin {
      client {
        ...LoginForm
        user {
          email
          firstName
          phoneNumber
        }
        household {
          coClient {
            user {
              firstName
            }
          }
        }
      }
    }
  `,
  [XLoginFormFragment]
);

type Content = {
  first_name?: string;
  body?: string;
};

function getHeader(content?: Content, otpChannel?: OtpChannel, client?: ResultOf<typeof XLoginQuery>['client']) {
  switch (otpChannel) {
    case OtpChannel.Email:
    case OtpChannel.Sms:
    case OtpChannel.Unknown:
      return 'Enter your security code';
  }
  if (content?.first_name) {
    return `Welcome back, ${content.first_name}!`;
  }
  if (client) {
    if (client.household.coClient?.user.firstName) {
      return `Welcome back, ${client.user.firstName} and ${client.household.coClient.user.firstName}!`;
    }
    return `Welcome back, ${client.user.firstName}!`;
  }
  return 'Log in to Savvy';
}

function getBody(
  content: Content,
  otpChannel?: OtpChannel | null,
  returnURL?: string,
  variant?: Variant,
  nonAdvisoryUsersEnabled?: boolean
) {
  if (otpChannel === OtpChannel.Email) {
    return `Enter the 6-digit code we sent to your email.`;
  } else if (otpChannel === OtpChannel.Sms || otpChannel === OtpChannel.Unknown) {
    return `Enter the 6-digit code we sent to your phone number.`;
  } else if (content.body) {
    return content.body;
  } else if (returnURL && returnURL.includes('checkin')) {
    return `Please log in to start your check-in.`;
  }
  return (
    <>
      {`Don’t have a Savvy account? `}
      {nonAdvisoryUsersEnabled ? (
        <XLink href="create-account" rel="noreferrer" target="_self" variant={variant}>
          Create an account
        </XLink>
      ) : (
        <XLink href="https://savvywealth.com/clients" rel="noreferrer" target="_blank" variant={variant}>
          Learn more about becoming a client at Savvy
        </XLink>
      )}
    </>
  );
}

function XLoginInner({ flags }: LDProps) {
  const router = useRouter();
  const isReAuth = !!router.query['reAuth'];
  const [isLoadingContent, setIsLoadingContent] = useState(!!router.query['login_context']);
  const isSmallScreen = useIsSmallScreen();
  const [content, setContent] = useState<Content>({});
  const { login, user } = useAuth();

  const [getClientData, { data: clientData, loading: clientDataLoading }] = useLazyQueryWithErrorLogging(XLoginQuery, {
    context: {
      headers: {
        authorization: getAuthHeader(router),
      },
    },
  });
  useEffect(() => {
    simpleServerTest();

    if (router.query['login_context']) {
      fetch(`${apiHost}/landing_page/decrypt?content=${encodeURIComponent(String(router.query['login_context']))}`)
        .then((response) => {
          return response.json() as Promise<Content>;
        })
        .then((responseJson) => {
          setContent(responseJson);
          setIsLoadingContent(false);
        })
        .catch(() => {
          setIsLoadingContent(false);
        });
    }
    if (getLoginContext(router)) {
      void getClientData();
      return;
    }
  }, []);
  return (
    <XLoginView
      clientData={clientData}
      content={content}
      isReAuth={isReAuth}
      isLoadingContent={isLoadingContent}
      clientDataLoading={clientDataLoading}
      returnUrl={getReturnUrl(router, user, isSmallScreen)}
      login={login}
      flags={flags}
    />
  );
}

export type XLoginViewProps = Readonly<{
  clientData?: ResultOf<typeof XLoginQuery>;
  content: Content;
  isReAuth?: boolean;
  isLoadingContent?: boolean;
  clientDataLoading?: boolean;
  returnUrl: string;
  login: (data: LoginData) => Promise<LoginReturn>;
}>;
export function XLoginView({
  clientData,
  content,
  isReAuth,
  isLoadingContent,
  clientDataLoading,
  returnUrl,
  login,
  flags,
}: XLoginViewProps & LDProps): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useIsSmallScreen();
  const isSubmittingRef = useRef(false);
  useEffect(() => {
    if (clientData?.client.user.email) {
      formik.setFieldValue('email', clientData.client.user.email);
    }
  }, [clientData]);

  const formik: LoginFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(MAX_STRING_LENGTH, `Email must be at most ${MAX_STRING_LENGTH} characters`)
        .test(
          'email-is-client',
          'This app is not configured for advisor accounts. Please log in with your client account instead.',
          (email) =>
            Platform.OS === 'web' || // Don't run this validation on web
            !email?.toLowerCase().includes('@savvyadvisors.com')
        )
        .required('Email is required'),
      password: Yup.string()
        .max(MAX_STRING_LENGTH, `Password must be at most ${MAX_STRING_LENGTH} characters`)
        .required('Password is required'),
      submit: Yup.string().nullable(),
    }),
    onSubmit: (values, helpers) => {
      if (isSubmittingRef.current) {
        return;
      }
      isSubmittingRef.current = true;
      login({
        type: AuthFactor.First,
        payload: {
          email: values.email,
          password: values.password,
        },
      })
        .then(({ loginErr }) => {
          if (loginErr) {
            console.warn(loginErr);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: loginErr });
            helpers.setSubmitting(false);
          }
        })
        .finally(() => {
          isSubmittingRef.current = false;
        });
    },
  });

  if (isLoadingContent || clientDataLoading) {
    return <XCircularLoadingIndicator />;
  }

  const otpChannel = errMsgToOtpChannel[formik.errors.submit ?? ''];
  const otpSent = otpChannel != null;

  return (
    <View
      style={{
        marginHorizontal: isSmallScreen ? 24 : 0,
      }}
    >
      <XHead>
        <title>Savvy | Log in to Your Account</title>
        <meta
          name="description"
          content="Log in to view and manage tasks from your financial advisor, track your real-time net worth, and more."
        />
      </XHead>

      <XLoginLayout>
        <View style={{ flexGrow: 1 }}>
          <View>
            <XTypography
              variant={isSmallScreen ? 'h5' : 'h3'}
              color={theme.palette.text.charcoal}
              fontWeight="600"
              style={{ paddingBottom: 8 }}
            >
              {getHeader(content, otpChannel, clientData?.client)}
            </XTypography>
          </View>
          <View>
            <XTypography variant={isSmallScreen ? 'body2' : 'body1'} color="textSecondary" style={{ marginBottom: 40 }}>
              {getBody(
                content,
                otpChannel,
                returnUrl,
                isSmallScreen ? 'body2' : 'body1',
                flags?.['nonAdvisoryUsers'] === true
              )}
            </XTypography>
          </View>
          {isReAuth && (
            <View style={{ marginBottom: 16 }}>
              <XTypography color={theme.palette.error.main} variant="caption">
                Your session has expired, please log in again.
              </XTypography>
            </View>
          )}
          {otpSent ? <XOtpLoginForm formik={formik} /> : <XLoginForm clientFrag={clientData?.client} formik={formik} />}
        </View>
      </XLoginLayout>
    </View>
  );
}

// This is to help engineers know if the server is alive and working
const simpleServerTest = () => {
  if (process.env['NODE_ENV'] === 'development') {
    // Simple Test to confirm Server is alive and working
    fetch(`${apiHost}/test`)
      .then((response) => {
        return response.json() as Promise<{ exception?: string }>;
      })
      .then((responseJson) => {
        if (responseJson.exception?.includes('ActiveRecord::PendingMigrationError')) {
          toast.error('Pending Migration Error');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!error.response) {
          toast.error('Server Error');
        }
      });
  }
};

export const XLogin = withGuestGuard(withLDConsumer()(XLoginInner));
