import { graphql } from 'shared/gql';
import { TrackEventInput } from 'shared/generated/graphql';
import { useRouter } from 'shared/hooks/use-router';
import { useMutationWithErrorLogging } from 'shared/hooks/useMutationWithErrorLogging';
import { mapRouteToPageName } from './utils';
import { NextRoute } from 'shared/generated/next-routes';

const UseTrackEventMutation = graphql(/* GraphQL */ `
  mutation UseTrackEvent($input: TrackEventInput!) {
    trackEvent(input: $input) {
      __typename
    }
  }
`);

/**
 * Track an event in MixPanel. Runs asynchronously and has a low chance of
 * failing without retries due to using raw `POST` under-the-hood. This is a
 * okay tradeoff for a method we'll be calling relatively frequently that does
 * not need to be 100% reliable
 *
 * @param event - The event to track
 */
export function useTrackEvent<Event extends keyof TrackEventInput>(
  event: Event
): (data: Omit<NonNullable<TrackEventInput[Event]>, 'pageName' | 'route' | 'urlQueryParams'>) => void {
  const router = useRouter();
  const [mutation] = useMutationWithErrorLogging(UseTrackEventMutation);
  const pageName = mapRouteToPageName(router.pathname as NextRoute);
  if (pageName == null) {
    return () => {
      /* empty */
    };
  }

  return (data?: Omit<NonNullable<TrackEventInput[Event]>, 'pageName' | 'route' | 'urlQueryParams'>) => {
    mutation({
      variables: {
        input: {
          [event]: {
            ...data,
            pageName,
            route: router.pathname,
            urlQueryParams: router.query,
          },
        },
      },
    });
  };
}
