import { Variant } from '@mui/material/styles/createTypography';
import { XTypographyVariantStyles } from './types';
import { typography } from 'shared/theme';

export const mapVariantToStyles: Record<Variant, XTypographyVariantStyles> = {
  button: typography.button,
  body1: {
    ...typography.body1,
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: 24,
  },
  body2: {
    ...typography.body2,
    fontSize: 14,
    lineHeight: 22,
  },
  subtitle1: {
    ...typography.subtitle1,
    fontSize: 16,
    lineHeight: undefined, // TODO: implement this
  },
  subtitle2: {
    ...typography.subtitle2,
    fontSize: 14,
    lineHeight: 22,
  },
  overline: {
    ...typography.overline,
    fontSize: 12,
    letterSpacing: undefined, // TODO: implement this
    lineHeight: undefined, // TODO: implement this
  },
  caption: {
    ...typography.caption,
    fontSize: 12,
    lineHeight: undefined,
  },
  h1: {
    ...typography.h1,
    fontSize: 56,
    letterSpacing: undefined, // TODO: implement this
    lineHeight: undefined, // TODO: implement this
  },
  h2: {
    ...typography.h2,
    fontSize: 48,
    letterSpacing: undefined, // TODO: implement this
    lineHeight: undefined, // TODO: implement this
  },
  h3: {
    ...typography.h3,
    fontSize: 32,
    letterSpacing: undefined, // TODO: implement this
    lineHeight: undefined, // TODO: implement this
  },
  h4: {
    ...typography.h4,
    fontSize: 32,
    letterSpacing: undefined, // TODO: implement this
    lineHeight: undefined, // TODO: implement this
  },
  h5: {
    ...typography.h5,
    fontSize: 24,
    fontWeight: '600',
    letterSpacing: 0, // TODO: implement this
    lineHeight: 24, // TODO: implement this
  },
  h6: {
    ...typography.h6,
    fontSize: 20,
    letterSpacing: undefined, // TODO: implement this
    lineHeight: undefined, // TODO: implement this
  },
};
