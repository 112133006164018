import { useEffect, useRef } from 'react';
import { useTrackEvent } from './use-track-event';

/**
 * Track an event in MixPanel. Runs asynchronously and has a low chance of
 * failing without retries due to using raw `POST` under-the-hood. This is a
 * okay tradeoff for a method we'll be calling relatively frequently that does
 * not need to be 100% reliable
 *
import { useTrackEvent } from "./use-track-event";
 * @param event - The event to track
 * @param data - The payload associated with the event
 */
export function useTrackPageView() {
  const trackPageImpression = useTrackEvent('pageImpression');
  const trackPageViewDuration = useTrackEvent('pageViewDuration');
  const mountTime = useRef(Date.now());

  useEffect(() => {
    trackPageImpression({});
    const onUnload = () => {
      trackPageViewDuration({ duration: Date.now() - mountTime.current });
    };
    window.addEventListener('beforeunload', onUnload);
    return () => {
      onUnload();
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  return null;
}
