import defaultGuard from 'shared/utils/default-guard';
import { NextRoute } from 'shared/generated/next-routes';
import { TrackedClickableElementName } from 'shared/tracking/tracked-clickable-element-name';

export type InteractionType = 'button_click';
export type TrackedHtmlElement = 'button' | 'link';
export type TrackedButtonAddedProps = {
  elementName: TrackedClickableElementName;
  target?: '_blank';
  rel?: 'noreferrer';
};

/** @sorted */
export type PageName =
  | 'account-setup'
  | 'account-sleeve'
  | 'alternative-funds'
  | 'animated-splash'
  | 'centers-of-influence'
  | 'checkin'
  | 'client-details'
  | 'client-household-goals'
  | 'clients'
  | 'co-pilot'
  | 'confirm-email'
  | 'create-account'
  | 'create-password'
  | 'discovery'
  | 'delete-contact'
  | 'files'
  | 'financial-plan'
  | 'goals'
  | 'heldaway-account'
  | 'holdings'
  | 'household'
  | 'risk-questionnaire'
  | 'household-service-calendar'
  | 'internal-bulk-upload'
  | 'bulk-upload-docusign-envelope-csv-upload'
  | 'internal-create-advisor'
  | 'internal-marketing'
  | 'internal-setup-account-confirmation'
  | 'invalid-invite-token-dialog'
  | 'login'
  | 'login-as-client'
  | 'logout-as'
  | 'managed-account'
  | 'marketing'
  | 'marketing-asset-details'
  | 'marketing-discovery'
  | 'my-account'
  | 'my-advisor'
  | 'net-worth'
  | 'offering'
  | 'offerings'
  | 'offering-type'
  | 'onboarding'
  | 'onboarding-dashboard'
  | 'planning'
  | 'portfolio'
  | 'portfolio-strategy'
  | 'portfolio-strategies'
  | 'prospects'
  | 'refresh-invite-token-dialog'
  | 'reset-password'
  | 'reporting'
  | 'rmds'
  | 'savvy-portfolio'
  | 'schedule'
  | 'service-calendar'
  | 'settings'
  | 'tasks'
  | 'take-risk-questionnaire'
  | 'welcome'
  | 'unknown';

export function mapRouteToPageName(route: NextRoute): PageName | null {
  switch (route) {
    case '/account-setup':
      return 'account-setup';
    case '/advisor/dashboard/alternative-funds':
      return 'alternative-funds';
    case '/advisor/dashboard/client/[salesforceAccountId]':
      return 'household';
    case '/advisor/dashboard/client/[salesforceAccountId]/details':
      return 'client-details';
    case '/advisor/dashboard/client/[salesforceAccountId]/files':
      return 'files';
    case '/advisor/dashboard/client/[salesforceAccountId]/net-worth':
      return 'net-worth';
    case '/advisor/dashboard/client/[salesforceAccountId]/planning':
      return 'planning';
    case '/advisor/dashboard/client/[salesforceAccountId]/portfolio/[portfolioId]':
      return 'portfolio';
    case '/advisor/dashboard/client/[salesforceAccountId]/portfolio/account/[managedAccountId]':
      return 'managed-account';
    case '/advisor/dashboard/client/[salesforceAccountId]/portfolio/account/sleeve/[accountSleeveId]':
      return 'account-sleeve';
    case '/advisor/dashboard/client/[salesforceAccountId]/portfolio/external-account/[heldawayAccountId]':
      return 'heldaway-account';
    case '/advisor/dashboard/client/[salesforceAccountId]/portfolio/strategy':
      return 'portfolio-strategy';
    case '/advisor/dashboard/client/[salesforceAccountId]/portfolio/strategy/[portfolioStrategyId]':
      return 'portfolio-strategy';
    case '/advisor/dashboard/client/[salesforceAccountId]/risk-questionnaire':
      return 'risk-questionnaire';
    case '/advisor/dashboard/client/[salesforceAccountId]/service-calendar':
      return 'household-service-calendar';
    case '/advisor/dashboard/clients':
      return 'clients';
    case '/advisor/dashboard/co-pilot':
      return 'co-pilot';
    case '/advisor/dashboard/cois':
      return 'centers-of-influence';
    case '/advisor/dashboard/delete-contact':
      return 'delete-contact';
    case '/advisor/dashboard/marketing':
      return 'marketing';
    case '/advisor/dashboard/marketing/asset/[boxFileId]':
      return 'marketing-asset-details';
    case '/advisor/dashboard/marketing/discovery':
      return 'marketing-discovery';
    case '/advisor/dashboard/offerings':
      return 'offerings';
    case '/advisor/dashboard/offerings/[offeringId]':
      return 'offering';
    case '/advisor/dashboard/offerings/type/[offeringType]':
      return 'offering-type';
    case '/advisor/dashboard/prospects':
      return 'prospects';
    case '/advisor/dashboard/reporting':
      return 'reporting';
    case '/advisor/dashboard/rmds':
      return 'rmds';
    case '/advisor/dashboard/service-calendar':
      return 'service-calendar';
    case '/advisor/dashboard/settings':
      return 'settings';
    case '/advisor/dashboard/tasks':
      return 'tasks';
    case '/advisor/login-as/[clientUserId]':
      return 'login-as-client';
    case '/animated-splash':
      return 'animated-splash';
    case '/checkin':
      return 'checkin';
    case '/confirm-email':
      return 'confirm-email';
    case '/create-account':
      return 'create-account';
    case '/create-password':
      return 'create-password';
    case '/dashboard/account':
      return 'my-account';
    case '/dashboard/advisor':
      return 'my-advisor';
    case '/files/[[...boxPath]]':
      return 'files';
    case '/financial-plan':
      return 'financial-plan';
    case '/goals':
      return 'goals';
    case '/risk-questionnaires/take':
      return 'take-risk-questionnaire';
    case '/internal/admin/marketing':
      return 'internal-marketing';
    case '/internal/admin/ops/bulk-upload':
      return 'internal-bulk-upload';
    case '/internal/admin/ops/create-advisor':
      return 'internal-create-advisor';
    case '/internal/admin/ops/setup-account-confirmation':
      return 'internal-setup-account-confirmation';
    case '/invalid-invite-token-dialog':
      return 'invalid-invite-token-dialog';

    case '/login':
      return 'login';
    case '/logout-as':
      return 'logout-as';
    case '/onboarding':
      return 'onboarding';
    case '/portfolio':
      return 'portfolio';
    case '/portfolio/accounts/[managedAccountId]':
      return 'managed-account';
    case '/portfolio/external-accounts/[heldawayAccountId]':
      return 'heldaway-account';
    case '/portfolio/holdings':
      return 'holdings';
    case '/refresh-invite-token-dialog':
      return 'refresh-invite-token-dialog';
    case '/reset-password':
      return 'reset-password';
    case '/savvy-portfolio/[portfolioId]':
      return 'savvy-portfolio';
    case '/schedule/[advisorId]':
      return 'schedule';
    case '/tasks':
      return 'tasks';
    case '/welcome':
      return 'welcome';
    case '/advisor/dashboard':
    case '/401':
    case '/404':
    case '/500':
    case '/advisor/dashboard/auth':
    case '/advisor/dashboard/callback':
    case '/advisor/dashboard/prospect/[prospect-data-id]':
    case '/advisor/dashboard/trigger-error':
    case '/advisor/error-testing':
    case '/dashboard':
    case '/index': // TODO: Fix
    case '/internal/admin/ops/[advisorId]':
    case '/plaid-redirect':
    case '/redirect-to-mobile-client-app/reset-password':
    case '/internal/admin/ops/oauth-callback':
    case '/advisor/dashboard/client/user/[userId]':
    case '/internal/jamison':
      // no-dd-sa:typescript-best-practices/no-console
      console.error(`Tried to track impression for route without a page name: ${route}`);
      return null;
    default:
      defaultGuard(route);
      return 'unknown';
  }
}
