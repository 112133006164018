import { XOtpForm } from './x-otp-form';
import { LoginFormik } from 'shared/x-pages/savvy-auth/x-login-types';
import { useMutationWithErrorLogging } from 'shared/hooks/useMutationWithErrorLogging';
import { graphql } from 'shared/gql';
import { AuthFactor } from 'shared/utils/savvy-auth';
import { useAuth } from 'shared/hooks/use-auth';
import { getReturnUrl } from 'shared/utils/routing';
import { toast } from 'shared/x-components/x-toast';
import { useIsSmallScreen } from 'shared/hooks/use-is-small-screen';
import { XTypography } from 'shared/x-components/x-typography';
import { useRouter } from 'shared/hooks/use-router';
import { XLink } from 'shared/x-components/x-link';

const otpLoginFormMutation = graphql(/* GraphQL */ `
  mutation OtpLoginForm($input: ResendOtpInput!) {
    resendOtp(input: $input) {
      error
    }
  }
`);

export type OtpLoginFormProps = {
  formik: LoginFormik;
};
export function XOtpLoginForm({ formik }: OtpLoginFormProps) {
  const [resendEmailOtp] = useMutationWithErrorLogging(otpLoginFormMutation, {
    variables: {
      input: {
        channel: 'email',
        currentPassword: formik.values.password,
        userEmail: formik.values.email,
      },
    },
  });
  const handleSendEmailOtp = async () => {
    const { data } = await resendEmailOtp();
    const error = data?.resendOtp?.error;
    if (error == null) {
      toast.success('Email sent!');
    }
    return error;
  };
  const { login, user } = useAuth();
  const router = useRouter();
  const isSmallScreen = useIsSmallScreen();

  return (
    <>
      <XOtpForm
        userEmail={formik.values.email}
        userInputPassword={formik.values.password}
        onSendEmail={handleSendEmailOtp}
        onSubmit={async (otp) => {
          const { loginErr } = await login({
            type: AuthFactor.Second,
            payload: {
              email: formik.values.email,
              password: formik.values.password,
              otp_attempt: otp,
            },
          });
          if (loginErr) {
            console.warn(loginErr);
            formik.setStatus({ success: false });
            formik.setErrors({ submit: loginErr });
            formik.setSubmitting(false);
            return false;
          }
          router.push(getReturnUrl(router, user, isSmallScreen));
          return true;
        }}
      />
      <XTypography variant="body1" color="textSecondary" style={{ marginTop: 32 }}>
        Still having trouble?{' '}
        <XLink href="mailto:client-service@savvywealth.com" style={{ fontWeight: '600' }}>
          Contact us
        </XLink>
      </XTypography>
    </>
  );
}
