import { XLogin } from 'shared/x-pages/savvy-auth/x-login';
import type { NextPage } from 'next';
import { useTrackPageView } from 'shared/tracking/use-track-page-view';

const Login: NextPage = () => {
  useTrackPageView();
  return <XLogin />;
};

export default Login;
