import { useMutationWithErrorLogging } from 'shared/hooks/useMutationWithErrorLogging';
import { useRouter } from 'shared/hooks/use-router';
import { isSavvyTestUserEmail } from 'shared/utils/internal-savvy';
import { graphql } from 'shared/gql';
import { JWTContextUser } from 'shared/api/user-api';
import { XTypography } from 'shared/x-components/x-typography';
import { XLink } from 'shared/x-components/x-link';
import { View } from 'react-native';

const XResetClientIntakeDataFooterButtonMutation = graphql(/* GraphQL */ `
  mutation XResetClientIntakeDataFooterButton($input: ResetClientIntakeDataInput!) {
    resetClientIntakeData(input: $input) {
      clientIntakeData {
        id
        intakeData
      }
    }
  }
`);

type Props = {
  user: JWTContextUser | null;
};

export const XResetClientIntakeDataFooterButton = ({ user }: Props) => {
  const router = useRouter();

  const [resetClientIntakeData] = useMutationWithErrorLogging(XResetClientIntakeDataFooterButtonMutation);

  if (process.env['NODE_ENV'] === 'production' && !isSavvyTestUserEmail(user ? user.email : null)) {
    return null;
  }

  return (
    <>
      <View style={{ paddingHorizontal: 2, paddingBottom: 3 }}>
        <XLink
          href="#"
          onClick={() => {
            void resetClientIntakeData({
              variables: {
                input: {},
              },
            });
            router.webOnlyReloadPage();
          }}
          underline="none"
        >
          <XTypography variant="caption" color="textSecondary">
            Reset Intake Data
          </XTypography>
        </XLink>
      </View>
      <View style={{ paddingHorizontal: 2 }}>
        <XTypography variant="caption" color="textSecondary">
          •
        </XTypography>
      </View>
    </>
  );
};
